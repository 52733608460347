import { useDispatch } from "react-redux"

import classNames from "classnames"
import moment from "moment"

import { setActiveSession } from "../activeEventSlice"

import { Appointments } from "@devexpress/dx-react-scheduler-material-ui"

export function ConferenceAppointment({
  children,
  data,
  onClick,
  classes,
  toggleVisibility,
  onAppointmentMetaChange,
  color,
  textColor,
  ...restProps
}) {
  const props = {
    isShaded: restProps.isShaded,
    resources: restProps.resources,
    style: restProps.style,
    type: restProps.type,
  }

  const dispatch = useDispatch()

  return (
    <>
      {!data.attended ? (
        <>
          {(data.rsvp === "accept" || data.rsvp === "tentative") && (
            <div
              className={classNames(
                "rsvpIndicator",
                data.rsvp,
                moment().isAfter(moment(data.endDate)) && "fade"
              )}
            >
              <span className={classNames("icon", data.rsvp)} />
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className={classNames(
              "rsvpIndicator attended",
              moment().isAfter(moment(data.endDate)) && "fade"
            )}
          >
            <span className={classNames("icon check")} />
          </div>
        </>
      )}

      <Appointments.Appointment {...props}>
        <div
          style={{ backgroundColor: color }}
          className={classNames(
            "appointmentInner conference-event",
            data.rsvp === "decline" && "decline",
            data.rsvp === "tentative" && "tentative",
            moment().isAfter(moment(data.endDate)) && "fade"
          )}
          onClick={({ target }) => {
            toggleVisibility()
            onAppointmentMetaChange({
              target: target.parentElement.parentElement,
              data,
            })
            dispatch(setActiveSession(data))
          }}
        >
          {!children ? (
            <div style={{ marginLeft: 6, marginTop: 1 }}>{data.title}</div>
          ) : (
            <header
              style={{
                color: textColor,
              }}
            >
              <div
                style={{
                  whiteSpace: "pre",
                  marginBottom: -7,
                  marginTop: -3,
                }}
              >
                {data.title}
              </div>
              <div>
                {moment(data.startDate).format("h:mmA")} -{" "}
                {moment(data.endDate).format("h:mmA")}
              </div>
            </header>
          )}
        </div>
      </Appointments.Appointment>
    </>
  )
}

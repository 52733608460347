import { Component } from "react"
import { connect, useDispatch } from "react-redux"
import { changeNavbar } from "../../../actions"

import { Button, Stack } from "@mui/material"
import { Link } from "react-router-dom"

import classNames from "classnames"
import { fetchSingleUserEvent } from "../../../store/reducers/calendar"
import {
  changeEventTab,
  fetchActiveEvent,
  fetchConference,
  fetchConferenceEventUserEvent,
  fetchConferenceSessions,
  fetchEventAttendees,
  fetchEventMessages,
} from "./activeEventSlice"

import { getAssessments } from "@mobilemind/common/src/actions/course"

import EventAttendees from "./EventAttendees"
import EventDetails from "./EventDetails"
import EventHeader from "./EventHeader"
import EventUpdates from "./EventUpdates"

import theme from "@mobilemind/common/src/theme/theme"
import moment from "moment"

import "../../../styles/event-single.scss"

import Loading from "@mobilemind/common/src/components/Loading"

import { SidebarLocationMap } from "@mobilemind/common/src/components/events/SidebarLocationMap"
import { SidebarLocationsAccordion } from "@mobilemind/common/src/components/events/SidebarLocationsAccordion"

import { useHistory, withRouter } from "react-router-dom"
import BackArrow from "../../../icons/BackArrow"
import ConferenceSchedule from "./ConferenceSchedule"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import EventFeedback from "./EventFeedback"
import EventRubric from "./EventRubric"

const mapStateToProps = (
  { categories, sidebar, session, calendar, assessments, activeEvent },
  ownProps
) => {
  let userEvent = calendar.userEvents.data.find(
    (event) => event.relationships.field_event.data.id === activeEvent.id
  )

  return {
    categories,
    sidebar,
    isConference: ownProps.location.pathname.includes("/conference"),
    isObservation: ownProps.location.pathname.includes("/observation"),
    session,
    calendar,
    userEvent,
    assessments,
    activeEvent,
  }
}

const mapDispatchToProps = {
  fetchActiveEvent,
  fetchEventMessages,
  fetchConference,
  fetchConferenceSessions,
  fetchSingleUserEvent,
  changeEventTab,
  fetchConferenceEventUserEvent,
  fetchEventAttendees,
  changeNavbar,
  getAssessments,
}

function SidebarEvent(props) {
  const history = useHistory()

  function goBack() {
    if (props.conferenceId) {
      history.push("/events/conference/" + props.conferenceId)
    } else {
      history.push("/events")
    }
  }

  const { activeEvent, isObservation, eventTypeLabel } = props
  const dispatch = useDispatch()

  const isCheckedIn =
    (activeEvent.checkedInAttendees &&
      activeEvent.checkedInAttendees.find(
        (attendee) => attendee.id === props.session.user.id
      )) ||
    activeEvent.isCheckedIn

  const showFeedback =
    moment().isAfter(moment(activeEvent.startDate)) && isCheckedIn

  return (
    <div
      id="sidebar"
      className={classNames(
        "sidebar sidebar-event",
        !props.isConference && activeEvent.parent && "shift",
        !activeEvent.fetched && "disabled"
      )}
    >
      {props.conferenceId &&
        !props.isConference &&
        activeEvent.parent &&
        activeEvent.parent.attributes && (
          <section
            className="conferenceLabel"
            style={{ backgroundColor: theme.palette.primary.dark }}
          >
            <Link to={"/events/conference/" + props.conferenceId}>
              <span className="icon" />
              {activeEvent.parent.attributes.name}
            </Link>
          </section>
        )}
      <div className="entity-meta">
        {props.conferenceId && (
          <Button className="backButton" onClick={goBack}>
            <BackArrow />
            <span
              style={{ marginLeft: 10, color: theme.palette.primary.main }}
              className="buttonText"
            >
              Back
            </span>
          </Button>
        )}
      </div>

      <ul className="sidebar-tabs">
        {props.isConference && (
          <li
            style={{
              backgroundColor:
                activeEvent.currentTab === "schedule" &&
                theme.palette.secondary.main,
            }}
            onClick={() => {
              dispatch(changeEventTab("schedule"))
            }}
          >
            <span className="icon calendar" /> Schedule
          </li>
        )}
        <li
          style={{
            backgroundColor:
              activeEvent.currentTab === "details" &&
              theme.palette.secondary.main,
          }}
          onClick={() => {
            dispatch(changeEventTab("details"))
          }}
        >
          <span className="icon details-info" /> Details
        </li>
        <li
          style={{
            backgroundColor:
              activeEvent.currentTab === "updates" &&
              theme.palette.secondary.main,
          }}
          onClick={() => {
            dispatch(changeEventTab("updates"))
          }}
        >
          <span className="icon message" /> Updates
        </li>
        {!isObservation ? (
          <li
            style={{
              backgroundColor:
                activeEvent.currentTab === "attendees" &&
                theme.palette.secondary.main,
            }}
            onClick={() => {
              dispatch(changeEventTab("attendees"))
            }}
          >
            <span className="icon attendees" /> Participants
          </li>
        ) : (
          <>
            {activeEvent.hasRubric && (
              <li
                style={{
                  backgroundColor:
                    activeEvent.currentTab === "rubric" &&
                    theme.palette.secondary.main,
                }}
                onClick={() => {
                  dispatch(changeEventTab("rubric"))
                }}
              >
                <span className="icon rubric" /> Rubric
              </li>
            )}
          </>
        )}

        {(activeEvent.feedbackURL || activeEvent.feedbackForm) &&
          showFeedback && (
            <>
              {activeEvent.feedbackURL ? (
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={activeEvent.feedbackURL}
                  >
                    <span className="icon feedback" />
                    {eventTypeLabel} Feedback
                  </a>
                </li>
              ) : (
                <li
                  style={{
                    backgroundColor:
                      activeEvent.currentTab === "feedback" &&
                      theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    dispatch(changeEventTab("feedback"))
                  }}
                >
                  <span className="icon feedback" /> {eventTypeLabel} Feedback
                </li>
              )}
            </>
          )}
      </ul>

      {activeEvent.fetched && (
        <div>
          {activeEvent.meetingLink && (
            <>
              <section className="locationWrapper">
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ alignItems: "center", marginBottom: 1 }}
                >
                  <span className="icon video-conference" />
                  <strong>Meeting Link</strong>
                </Stack>
                <div>
                  <a
                    href={activeEvent.meetingLink}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {activeEvent.meetingLink.substring(0, 34)}...
                  </a>
                </div>
              </section>
            </>
          )}

          {activeEvent.location && !activeEvent.conferenceLocations.length && (
            <SidebarLocationMap
              location={activeEvent.location}
              eventRoom={activeEvent.eventRoom}
            />
          )}

          {activeEvent.conferenceLocations.length > 0 && (
            <SidebarLocationsAccordion
              locationId={activeEvent.scheduleFilters.locationId}
              locations={activeEvent.conferenceLocations}
            />
          )}

          {activeEvent.tags.length > 0 && (
            <div className="flexRow" style={{ flexWrap: "wrap" }}>
              {activeEvent.tags.map((tag) => {
                return <div className="tag-wrapper">{tag.attributes.name}</div>
              })}
            </div>
          )}

          <a
            style={{ display: "block", marginTop: 20 }}
            id="faq-link"
            href="https://knowledge.mobilemind.io/"
            rel="noreferrer"
            target="_blank"
          >
            <ButtonSmall>
              <span className="icon" /> Frequently Asked Questions
            </ButtonSmall>
          </a>
        </div>
      )}
    </div>
  )
}

function EventSingleLayout(props) {
  return <EventSingle {...props} theme={theme} />
}

class EventSingle extends Component {
  state = {
    mapsSource: null,
    currentTime: moment(),
  }

  fetchData = ({ refresh }) => {
    const { eventId } = this.props.match.params

    this.props
      .fetchActiveEvent({
        refresh,
        id: eventId,
        isConference: this.props.isConference,
        isObservation: this.props.isObservation,
      })
      .then((response) => {
        let conferenceId
        if (response.payload) {
          if (response.payload.isConference) {
            if (!refresh) {
              this.props.changeEventTab("schedule")
            }
            conferenceId =
              response.payload.data[0].attributes.drupal_internal__id
            this.props.fetchConferenceSessions({ id: conferenceId })
          }

          if (
            response.payload.data[0].type ===
            "mobilemind_event_entity--conference_event"
          ) {
            this.props.fetchConferenceEventUserEvent({
              id: response.payload.data[0].id,
            })
          }
        }

        this.props.fetchEventMessages()
        this.props.fetchEventAttendees()
      })

    if (this.props.match.params.conferenceId) {
      this.props.fetchConference({ id: this.props.match.params.conferenceId })
    }

    !this.props.assessments.fetched && this.props.getAssessments()
  }

  componentDidMount() {
    this.props.changeNavbar("sync")
    this.fetchData({ refresh: false })
    // This will pass the current time every 60 seconds so we can see if the event has started and
    // re-render the header, which will show the Check In container if necessary
    this.checkRegistrationWindow = setInterval(() => {
      if (this.props.activeEvent.currentTab !== "feedback") {
        this.fetchData({ refresh: true })
      }
      this.setState({ currentTime: moment() })
    }, 60000)
  }

  componentWillUnmount() {
    clearInterval(this.checkRegistrationWindow)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.conferenceId !==
      this.props.match.params.conferenceId
    ) {
      this.fetchData({ refresh: false })
    }
    if (
      this.props.activeEvent.id &&
      prevProps.activeEvent.id !== this.props.activeEvent.id
    ) {
      this.props.fetchSingleUserEvent({
        uuid: this.props.activeEvent.id,
        bundle: this.props.activeEvent.bundle,
        eventId: this.props.activeEvent.drupal_internal__id,
      })
    }
  }

  render() {
    const props = this.props
    const { sidebar, activeEvent, userEvent, theme } = props

    let eventTypeLabel =
      activeEvent.bundle === "event_base" ? "Event" : "Session"
    if (activeEvent.bundle === "conference") {
      eventTypeLabel = "Conference"
    }

    let loadingText = "Getting event..."
    if (props.isConference) {
      loadingText = "Getting conference..."
    }
    if (props.match.params.conferenceId) {
      loadingText = "Getting session..."
    }
    return (
      <div
        style={{ marginLeft: sidebar.isCollapsed ? 260 : 90 }}
        className={classNames(
          "page event-single",
          !props.isConference && activeEvent.parent && "shift",
          props.isConference && "conference",
          activeEvent.isFullScreen && "isFullScreen"
        )}
      >
        <SidebarEvent
          categories={this.props.categories}
          conferenceId={this.props.match.params.conferenceId}
          isConference={props.isConference}
          isObservation={props.isObservation}
          userEvent={userEvent}
          mapsSource={this.state.mapsSource}
          session={this.props.session}
          activeEvent={activeEvent}
          eventTypeLabel={eventTypeLabel}
        />

        {activeEvent.fetched ? (
          <>
            <EventHeader currentTime={this.state.currentTime} {...props} />

            {activeEvent.currentTab === "details" && (
              <EventDetails activeEvent={activeEvent} />
            )}
            {activeEvent.currentTab === "updates" && (
              <EventUpdates
                isConference={props.isConference}
                isObservation={props.isObservation}
                activeEvent={activeEvent}
              />
            )}
            {activeEvent.currentTab === "rubric" && (
              <EventRubric userEvent={userEvent} activeEvent={activeEvent} />
            )}
            {activeEvent.currentTab === "schedule" && (
              <ConferenceSchedule {...props} />
            )}
            {activeEvent.currentTab === "attendees" && (
              <EventAttendees userEvent={userEvent} activeEvent={activeEvent} />
            )}
            {activeEvent.currentTab === "feedback" && (
              <EventFeedback
                activeEvent={activeEvent}
                eventTypeLabel={eventTypeLabel}
              />
            )}
          </>
        ) : (
          <Loading color={theme.palette.secondary.main} message={loadingText} />
        )}
      </div>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventSingleLayout)
)
